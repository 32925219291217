@import "../../../shared/commonStyles.scss";

.navbar {
  display: flex;
  justify-content: center;
  background-color: rgb(233, 233, 233);
  padding: 10px 50px !important;
  height: 70px !important;

  .neonlogo {
    height: 50px;
  }

  .navbar-brand {
    display: inline-block;
  }

  .main_nav {
    justify-content: center;

    li {
      text-transform: uppercase;
    }
  }

  .navbar_nav {
    display: flex;
    gap: 40px;
    justify-content: center;
  }

  .nav_item {
    a {
      text-decoration: none;
      color: black;
      transition: 0.3s ease-in-out;
      font-weight: 600;
    }

    a:hover {
      color: $primary;
    }

    ul {
      max-height: 80vh;
      overflow-y: auto;
      max-width: 30%;
      li {
        a {
          white-space: normal;
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 10px 0px !important;

    .navbar-brand {
      margin-left: 17px !important;
    }

    .navbar-toggler {
      margin-right: 17px !important;
    }

    .main_nav {
      background: white !important;
      padding: 10px 20px !important;
      z-index: 9999 !important;
    }

    .nav_item ul {
      max-width: 100% !important;
    }
  }
}
