@import "../../../shared/commonStyles.scss";

.main {
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: rgb(248, 248, 248);
  padding: 50px 0 30px;

  .allProducts,
  .quick_links {
    h5 {
      font-size: 26px;
      font-weight: 600;
    }

    ul {
      list-style: none;
      padding-left: 0 !important;

      li {
        margin: 10px auto;
      }
    }

    a {
      text-decoration: none;
      color: black;
      transition: all 0.2s ease-in-out;
      text-transform: capitalize;
    }
    a:hover {
      cursor: pointer;
      color: $primary;
    }
  }

  .allProducts {
    text-align: left;
    max-width: 300px;
  }

  .quick_links {
    text-align: right;
  }

  .central_content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;

    .socials {
      display: flex;
      justify-content: center;
      gap: 20px;

      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $primary;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0px 0px 15px rgb(0 0 0 / 20%);
        :hover {
          cursor: pointer;
        }
      }
    }
  }

  .contact_info {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin: 50px auto;

    .contact_item {
      svg {
        color: $primary;
        font-size: 24px;
      }
      display: flex;
      justify-content: space-between;
      gap: 10px;
      max-width: 300px;

      a {
        text-decoration: none;
        color: black;
      }
    }
  }

  .credits {
    display: flex;
    justify-content: space-between;

    a {
      text-decoration: none;
      color: #f28828;
      font-weight: 600;
    }

    color: rgb(99, 99, 99);
  }
}

@media (max-width: 768px) {
  .main,
  .contact_info {
    flex-direction: column !important;
  }

  .contact_item {
    text-align: center;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .allProducts,
  .quick_links {
    text-align: center !important;
    max-width: 100% !important;
  }

  .credits {
    div {
      flex: 0 0 50% !important;
    }
  }
}
