@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/GothamBook.ttf");
  font-display: swap;
}

body {
  //   background-color: rgb(240, 240, 240) !important;
}
* {
  font-family: "Gotham";
}

.theme_color_primary {
  color: #e20004;
}

.f-600 {
  font-weight: 600;
}

.theme_btn_primary {
  background-color: #e20004 !important;
  color: white !important;
}

.bold {
  font-weight: bold !important;
}

.f-16 {
  font-size: 16px;
}
.f-18 {
  font-size: 18px;
}
.f-22 {
  font-size: 22px;
}
.f-26 {
  font-size: 26px;
}
.f-30 {
  font-size: 30px;
}
.f-34 {
  font-size: 34px;
}

label.Mui-focused {
  color: #e20004 !important;
}

// label.MuiInputLabel-root {
//   color: #e20004 !important;
// }
.MuiInput-underline:after {
  border-bottom-color: #e20004 !important;
}
.MuiOutlinedInput-root {
  & fieldset {
    border-color: #e20004 !important;
  }
  &:hover fieldset {
    border-color: #e20004 !important;
  }
  &.Mui-focused fieldset {
    border-color: #e20004 !important;
  }
}

.activeLink {
  color: #e20004 !important;
}

.dropdown-menu[data-bs-popper] {
  left: 42% !important;
  top: 20% !important;
}

.neyon {
  color: #e20004 !important;
  font-weight: 600;
  span {
    font-style: italic;
  }
}

a {
  text-decoration: none !important;
}

@media (max-width: 768px) {
  .navbar-toggler-icon {
    filter: brightness(0) !important;
  }
}
