@import "../../../shared/commonStyles.scss";

.main {
  .section_1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    min-height: 80vh;
    background: linear-gradient(rgba(41, 42, 45, 0.7), rgba(41, 42, 45, 0.7)),
      url("../../../../public/images/home/neon_intro.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .intro_big {
      color: rgb(225, 225, 225);
    }

    .intro_small {
      color: rgb(225, 225, 225);
    }
    .description {
      max-width: 60%;
      margin: 20px auto;
      font-size: 18px;
      color: rgb(225, 225, 225);
    }

    .slider_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .slider_content_one {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .slider_content_title,
      .slider_content_description {
        color: white;
        padding: 9px 15px;
        background-color: rgba(0, 0, 0, 0.57) !important;
        max-width: 70%;
        max-height: 210px;
        overflow-y: auto;
      }

      .slider_content_title {
        text-transform: uppercase;
      }
    }

    .horizontal_scroll {
      display: flex;
      gap: 40px;
      max-width: 100%;
      overflow-x: auto;
      align-items: center;
      padding: 12px 0;

      .highlighted_img {
        height: 300px !important;
        width: 100% !important;
        object-fit: contain;
      }
    }

    .swiper_slide {
      padding: 20px;
      height: 500px;
    }
  }

  .section_2,
  .section_3,
  .section_4,
  .section_5 {
    display: flex;
    justify-content: center;
    // margin-top: 120px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    min-height: 80vh;
  }

  .section_2 {
    min-height: 60vh !important;
    .intro {
      text-align: center;
      margin: 40px auto;
      padding: 10px;
      border-radius: 8px;
      color: white;
      background-color: $primary;
      h5 {
        font-weight: 600 !important;
      }
    }

    .section_2_content {
      display: flex;
      justify-content: center;
      gap: 40px;
      flex-wrap: wrap;

      .card_content {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        // margin-top: 20%;
        gap: 10px;
        align-items: center;
        flex-direction: column;
        text-align: center;

        svg {
          font-size: 50px !important;
          color: $primary;
        }
        .addIcon {
          font-size: 30px !important;
          margin: 0 15px;
        }
      }

      .card {
        width: 300px;
        height: 300px;
        border-radius: 10px;
        padding: 20px;
      }
    }
  }

  .section_3 {
    min-height: 100vh !important;
    background: linear-gradient(rgba(60, 60, 60, 0.7), rgba(60, 60, 60, 0.7)),
      url("../../../../public/images/home/worldwide.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;

    color: white;

    .stats {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 40px;

      .countup {
        font-size: 32px;
      }

      .stat_content {
        display: flex;
        justify-content: flex-start;
        margin-top: 30px;
        gap: 20px;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 200px;
      }
    }
  }

  .section_4 {
    // text-align: center;

    .intro {
      // margin: 60px auto;
    }

    .card {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      margin: auto;
      text-align: center;
      height: 90%;

      .product_info {
        margin-top: 20px;
      }

      a {
        text-decoration: none;
      }
    }

    .bestsellers {
      text-align: center;
    }

    .card_viewall {
      height: 90%;
      cursor: pointer;
      transition: 0.3s ease-in-out;
    }

    .card_viewall:hover {
      box-shadow: none;
    }

    .swiper_slide {
      padding: 20px;
      height: 400px;
    }
  }

  .section_4 {
    img.client_img {
      object-fit: contain !important;
      max-width: 100% !important;
    }
  }

  .section_5 {
    .swiper_slide {
      padding: 20px;
      height: 200px;
    }
  }

  .card {
    box-shadow: 0px 0px 15px rgb(0 0 0 / 20%);
  }
}

@media (max-width: 768px) {
  .intro_big {
    font-size: 38px !important;
  }

  .intro_small {
    font-size: 18px !important;
  }

  .slider_content {
    flex-direction: column !important;
    h5 {
      font-size: 18px !important;
    }

    .slider_content_one {
      order: 2 !important;
    }
    .slider_content_description,
    .slider_content_title {
      max-width: 100% !important;
    }

    .slider_content_description {
      max-height: 130px !important;
      overflow-y: auto !important;
    }

    .slider_content_two {
      order: 1 !important;

      img {
        max-height: 150px !important;
      }
    }
  }

  .description {
    max-width: 80% !important;
    font-size: 16px !important;
  }

  .section_2_content {
    flex-direction: column !important;
    margin: 60px auto;
    justify-content: center !important;

    .card {
      width: 100% !important;
    }
  }

  .section_4 {
    .card {
      width: 100% !important;
    }
  }
}
